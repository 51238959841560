const deviceTranslation = {
  mode: 'ko',
  menu: '마미박스',
  listMenu: '마미박스 리스트',
  versionMenu: '버전 관리',

  searchDevice: '장비 검색',
  editDeviceDetail: '번 마미박스 정보 수정',
  editDevice: '마미박스 수정',
  addDevice: '마미박스 추가',
  addDeviceVersion: '마미박스 버전 추가',
  recordingSetting: '녹화 설정',
  deviceSetting: '주변 장치 설정',
  functionSetting: '기능 설정',
  captureSwitchSetting: '캡쳐 스위치 설정',
  recordingScreenSetting: '녹화 화면 설정',
  info: '기본 정보',
  hospitalInfo: '병원 정보',

  hospitalLabel: '설치 병원',
  hospitalRoomLabel: '설치 진료실',
  maxRecordingTimeLabel: '최대 녹화 시간',
  motionThresholdLabel: '모션 감지 감도',
  recordingCardSelectModeLabel: '녹음 장치 선택',
  captureSoundLabel: '캡쳐 비프 음량',
  captureOnRecordingLabel: '녹화 중 캡쳐 가능 여부',
  pauseResumeLabel: '녹화재개/일시정지 소리 ON/OFF',
  diaryCaptureLabel: '산모수첩 캡쳐 사용',
  printerLabel: '프린터 사용(CP1300)',
  recordingBrightnessLabel: '녹화 밝기',
  recordingContrastLabel: '녹화 대비',

  devicePlaceHolder: '장비명 입력',
  descriptionPlaceHolder: '장비 설명 입력',
  hospitalRoomPlaceHolder: '진료실 선택',
  buildNumberPlaceHolder: '빌드 번호 입력',
  buildHashPlaceHolder: '빌드 해시 입력',
  versionNamePlaceHolder: '버전 이름 입력',
  versionDescriptionPlaceHolder: '버전 설명 입력',

  seq: '번호',
  name: '장비명',
  deviceName: '장비명(기번)',
  hospital: '설치병원',
  version: '버전',
  hospitalRoom: '설치진료실',
  activity: '마지막활동',
  motionThreshold: '모션감도',
  maxRecordingTime: '최대녹화시간',
  minute: '분',
  printer: '프린터',
  capture: '캡쳐기능',
  captureSwitch: '캡쳐스위치모드',
  recordingBrightness: '녹화밝기',
  recordingContrast: '녹화대비',
  recordingCardSelectMode: '녹음장치',
  captureOnRecording: '녹화중에만캡쳐',
  activeFlag: '활성상태',
  active: '활성화',
  inactive: '비활성화',
  activeDevice: '활성 마미박스',
  inactiveDevice: '비활성 마미박스',
  installFlag: '설치여부',
  install: '설치',
  installDevice: '설치된 마미박스',
  uninstallDevice: '미설치된 마미박스',
  description: '장비 설명',

  motionThreshold001: '[0.010] 매우 예민하게',
  motionThreshold005: '[0.050] 예민하게',
  motionThreshold018: '[0.180] 보통 (기본값)',
  motionThreshold022: '[0.220] 둔감하게',
  motionThreshold999: '[999.999] 모션감지 사용 안함',
  captureSwitchNone: '캡쳐 + 캡쳐',
  captureSwitchCs: '캡쳐 + 녹화시작_녹화종료',
  captureSwitchCp: '캡쳐 + 녹화재개_일시정지',
  captureSwitchSp: '녹화시작_녹화종료 + 녹화재개_일시정지',
  captureSwitchPs: '녹화재개_일시정지 + 녹화시작_녹화종료',
  recordingCardSelectModeDefault: '마미박스가 알아서 선택',
  recordingCardSelectModeYuh01: '신캡 3.5mm',
  recordingCardSelectModeEzcap: '게임독 울트라 3.5mm',
  recordingCardSelectModeAlc255: 'PN40 전면부 입력',
  recordingCardSelectModeUsb: 'USB 입력창지(0d8c:0014)',
  recordingCardSelectModeAv: '이지캡 (534d:0021)',

  buildNumber: '빌드 번호',
  buildHash: '빌드 해시',
  versionName: '버전 이름',
  versionDescription: '버전 설명',
  visibleFlag: '표시 여부',
  createdAt: '생성일시',
  updatedAt: '수정일시',

  assignedRoom: '배정된 진료실 있음',
  unassignedRoom: '배정된 진료실 없음',
  activeDeviceMessage: '번 마미박스를 활성화하시겠습니까?',
  inactiveDeviceMessage: '번 마미박스를 비활성화하시겠습니까?',
  inactiveDeviceWarningMessage: '* 비활성화된 박스는 종합모니터링에 표시되지 않습니다.',
  descriptionMessage: '* 보통 진료실명을 입력합니다.',
  deviceReflectMessage1: '* 빌드 248부터 사용 가능',
  deviceReflectMessage2: '* 빌드 251부터 사용 가능',
  deviceReflectMessage3: '* 빌드 252부터 사용 가능',
  deviceReflectMessage4: '* 빌드 253부터 사용 가능',
  deviceReflectMessage5: '* 빌드 257부터 사용 가능',
  deviceReflectMessage6: '* 빌드 283부터 사용 가능',
  deviceReflectMessage7: '* 빌드 251부터 저장 후 마미박스에 즉시 반영됩니다.',
  recordingCardSelectModeDefaultOption: '마미박스가 알아서 선택하게 함 (기본값)',
  recordingCardSelectModeYuh01Option: '신캡 3.5mm 입력 (YUH01/TC-UB570)',
  recordingCardSelectModeEzcapOption: '게임독 울트라 3.5mm 입력 (ezcap GameDock Ultra)',
  recordingCardSelectModeAlc255Option: '[PN40 전용] 전면부 입력, y잭 (ALC255 Analog)',
  recordingCardSelectModeUsbOption: '범용 USB 입력장치(마이크) (0d8c:0014)',
  recordingCardSelectModeAvOption: '이지캡 (534d:0021)',
  captureSwitchNoneOption: '[none] 캡쳐 + 캡쳐 (기본값)',
  captureSwitchCsOption: '[cs] 캡쳐 + 녹화시작_녹화종료',
  captureSwitchCpOption: '[cp] 캡쳐 + 녹화재개_일시정지',
  captureSwitchSpOption: '[sp] 녹화시작_녹화종료 + 녹화재개_일시정지',
  captureSwitchPsOption: '[ps] 녹화재개_일시정지 + 녹화시작_녹화종료',
  optionMessage1: '[0] 사용하지 않는다 (기본값)',
  optionMessage2: '[1] 사용한다',
  captureSoundOption1: '[1] 작게',
  captureSoundOption2: '[3] 보통 (기본값)',
  captureSoundOption3: '[5] 크게',
  captureOnRecordingOption1: '[0] 언제든 캡쳐',
  captureOnRecordingOption2: '[1] 녹화 중에만 캡쳐',
  pauseResumeOption1: '[0] 켠다 (기본값)',
  pauseResumeOption2: '[1] 끈다',
  recordingBrightnessOption1: '[-0.10] 10% 어둡게',
  recordingBrightnessOption2: '[-0.08] 8% 어둡게',
  recordingBrightnessOption3: '[-0.06] 6% 어둡게',
  recordingBrightnessOption4: '[-0.04] 4% 어둡게',
  recordingBrightnessOption5: '[-0.02] 2% 어둡게',
  recordingBrightnessOption6: '[0.00] 0% (기본값)',
  recordingBrightnessOption7: '[0.02] 2% 밝게',
  recordingBrightnessOption8: '[0.04] 4% 밝게',
  recordingBrightnessOption9: '[0.06] 6% 밝게',
  recordingBrightnessOption10: '[0.08] 8% 밝게',
  recordingBrightnessOption11: '[0.10] 10% 밝게',
  recordingContrastOption1: '[0.98] -2% 대비',
  recordingContrastOption2: '[1.00] 0% 대비',
  recordingContrastOption3: '[1.01] 1% 대비',
  recordingContrastOption4: '[1.02] 2% 대비',
  recordingContrastOption5: '[1.03] 3% 대비',
  recordingContrastOption6: '[1.05] 5% 대비',
  recordingContrastOption7: '[1.07] 7% 대비 (기본값)',
  deviceActiveOption1: '[1] 활성',
  deviceActiveOption2: '[0] 비활성',
  deviceInstallOption1: '[1] 설치',
  deviceInstallOption2: '[0] 미설치',
};

export default deviceTranslation;
