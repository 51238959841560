const csTranslation = {
  mode: 'ko',
  menu: '통합CS',

  csTitle: '통합 CS',
  appUserTableTitle: 'APP 유저 조회/작업',
  csRecordTableTitle: '초음파 동영상 조회/작업',
  csTableTitle: 'CS 처리내역 엿보기',

  csPlaceHolder: '통합 검색',
  csRecordPlaceHolder: '동영상 검색',
  csHistoryPlaceHolder: '처리내역 검색',
  insertSeq: '번호 입력',
  insertDate: '날짜 입력',
  insertReason: '사유 입력',
  changeTargetBarcode: '변경할 바코드',
  duplicateTargetBarcode: '복사할 바코드',
  uploadVideo: '업로드 영상',

  selectReason: '사유 선택',
  phone: '전화',
  channelTalk: '채널톡',
  other: '기타',

  userDetailInfo: '사용자 상세 정보',
  videoDetailInfo: '동영상 상세 정보',
  videoInfo: '동영상 정보',
  barcode: '바코드',
  signUpAt: '가입일',
  nickName: '닉네임',
  babyNickName: '태명',
  dueDate: '출산예정일',
  marketingAgreeAt: '제3자 제공 동의일',
  seq: '번호',
  name: '가입이름',
  phoneNumber: '전화번호',
  status: '회원상태',
  leave: '탈퇴함',
  normal: '정상',
  recordedAt: '촬영일시',
  capturedAt: '캡쳐일시',
  registeredAt: '등록일시',
  captureCount: '캡쳐 수',
  hospital: '병원',
  hospitalRoom: '진료실',
  device: '장비',
  activityType: '유형',
  reason: '사유',
  description: '처리내역',
  createdAt: '처리일시',
  actor: '처리자',
  blinded: '(블라인드)',
  videoBlind: '영상 블라인드',
  blind: '블라인드',
  setting: '설정',
  clear: '해제',
  changeBarcode: '바코드 이전',
  duplicateRecording: '영상 복사',
  editRecording: '영상 편집',
  edit: '편집',
  overwriteRecording: '영상 덮어쓰기',
  overwrite: '덮어쓰기',
  restoreRecording: '편집 영상 복원',
  restore: '복원',
  recordingSeq: '영상 번호',
  captureSeq: '캡쳐 번호',
  videoLength: '영상 길이',
  bucket: '버킷',
  file: '파일',
  recording: '동영상',
  capture: '캡쳐 이미지',
  successDownload: '다운로드 성공',
  failDownload: '다운로드 실패',
  editTime: '편집 시간',
  uploadFile: '업로드 파일',
  hideAllPhotos: '전체 캡쳐 블라인드',
  unhideAllPhotos: '전체 캡쳐 블라인드 해제',

  csDevice: '장비/',
  csHospital: '병원/',
  csHospitalRoom: '진료실/',
  csHospitalLogin: '병원 계정',
  csRecording: '영상/',
  csCapture: '캡쳐/',
  csBarcode: '바코드/',
  csBox: '마미박스제어',
  csApprest: '앱재시작',
  csRemotecmd: '원격명령',
  csEdit: '수정',
  csBlind: '블라인드 설정',
  csUnblind: '블라인드 해제',
  csDelete: '삭제(비활성화)',
  csUpdate: '수정',
  csCreate: '생성',
  csRefresh: '새로고침',
  csOverwrite: '덮어쓰기',
  csDuplicate: '복사',
  csLogins: '관리자계정',

  downloadAll: '전체 다운로드',
  csDownload: '처리내역 다운로드',
  csExcel: 'MDA-CS_처리내역.xlsx',

  editMessage: '편집되었습니다.',
  duplicateMessage: '복사되었습니다.',
  overwriteMessage: '덮어쓰기 되었습니다.',
  overwriteFailMessage: '덮어쓰기 실패',
  restoreMessage: '편집 영상이 복원되었습니다.',
  refreshMessage: '썸네일과 영상 길이를 새로고침 합니다.',
  refreshSuccessMessage: '초음파 영상 새로고침 완료',
  refreshFailMessage: '영상 정보 갱신 실패, 영상에 문제가 있을 수 있습니다.',
  searchMessage:
    '검색어를 입력 후 엔터를 누르면 모든 하위 항목에서 검색을 수행합니다. (바코드 검색에 유용)',
  changeBarcodeMessage: '바코드를 입력 시 바코드 이전, 미입력 시 블라인드 설정/해제',
};

export default csTranslation;
